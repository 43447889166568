import gsap from 'gsap';
import { initGSAP, animateObject } from "gsapanimator";
import { initDragTrack, stopDragTrack } from './dragtrack';

export function setupAnimations() {

    initGSAP();

    animateObject('.gsap-fade-in-right', {
        x: 0,
        opacity: 1,
        duration: 1
    }, {
        start: 'top 60%',
        end: 'bottom 40%',
        scrub: false,
        markers: false,
        //toggleActions: 'play pause reverse pause'
    }, false
    );

    animateObject('.gsap-fade-in-bottom', {
        y: 0,
        opacity: 1,
        duration: 0.8
    }, {
        start: 'top 60%',
        end: 'bottom 40%',
    }, false
    );

    animateObject('.gsap-fade-in', {
        opacity: 1,
        duration: 1
    }, {
        start: 'top 60%',
        end: 'bottom 40%',
    }, false
    );

    /*  animateObject('.sibling_img figure', {
         y: -100,
         opacity: 0,
         duration: 0.8
     }, {
         start: 'top 60%',
         end: 'bottom 40%',
     }
     ); */

    let parallax_imgs = document.querySelectorAll(".parallax-home-img");
    // create
    let mm = gsap.matchMedia();
    // add a media query. When it matches, the associated function will run
    mm.add("(min-width: 915px)", () => {

        let vpFactor = window.innerHeight > window.innerWidth ? 0.15 : 0.85;
        let vpH = window.innerHeight * 0.15;

        parallax_imgs.forEach(element => {
            gsap.to(element, {
                yPercent: (-1 * getRandomNumber(vpFactor * vpH, vpH)),
                ease: "none",
                scrollTrigger: {
                    trigger: ".parallax-container-home ",
                    start: "top 90%", // the default values
                    end: "bottom 20%",
                    scrub: true,
                },
            });
        });
        stopDragTrack();
    });

    mm.add("(max-width: 915px)", () => {
        initDragTrack(".img-track");
    });
}

function getRandomNumber(min, max) {
    return min + Math.floor(Math.random() * max);
}