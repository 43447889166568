import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

let tl;

export function initGSAP() {
    gsap.registerPlugin(ScrollTrigger);
    tl = gsap.timeline();
}

export function animateObject(elQuery, animation, scrollOptions, directionFrom = true) {
    let elements = document.querySelectorAll(elQuery);

    elements.forEach(element => {
        let _tl;
        if (scrollOptions) {
            scrollOptions.trigger = element;
            _tl = gsap.timeline({
                scrollTrigger: scrollOptions
            });
        }
        else
            _tl = gsap.timeline();

        if (directionFrom)
            _tl.from(element, animation);

        else
            _tl.to(element, animation);

        // tl.add(_tl);
    });
}