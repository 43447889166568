let lenisInstance;

export function initMobileNav(_lenisInstance) {
    lenisInstance = _lenisInstance;
    let nav_btn = document.getElementById('nav_btn');
    nav_btn.addEventListener('click', togglenav);
}

function togglenav(evt) {
    let btn = evt.target;
    let body = document.documentElement;


    if (body.classList.contains('menu_active')) {
        lenisInstance.start();
    }
    else {
        lenisInstance.stop();
    }

    body.classList.toggle('menu_active');
    btn.classList.toggle('active');

}

export function resetNav() {
    let btn = document.getElementById('nav_btn');
    let body = document.documentElement;
    body.classList.remove('menu_active');
    btn.classList.remove('active');

    lenisInstance.start();
}