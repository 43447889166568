
import gsap from "gsap";
import TextSlicer from 'text-slicer';

export function createStaggeredTextAppear() {
    setTimeout(() => {

        let container = document.querySelectorAll('.text-slicer');
        container.forEach((element) => {
            const textSlicer = new TextSlicer({
                container: element,
                splitMode: 'both',
                cssVariables: true,
                dataAttributes: true,
            });

            textSlicer.init();

            let targets = element.querySelectorAll('.char');

            gsap.from(targets, {
                y: 15 + (window.innerWidth / 100),
                opacity: 0,
                stagger: {
                    each: 0.018,
                    onComplete: function () {
                        let target = this.targets()[0];
                        target.classList.add('gsap-done');
                    }
                }
            });

            element.classList.remove('hidden');
        });

    }, 150);


}