let track;

export function initDragTrack(query) {

    track = document.querySelector(query);

    if (track) {
        window.addEventListener('mousedown', handleOnDown);
        window.addEventListener('mouseup', handleOnUp);
        window.addEventListener('mousemove', handleOnMove);

        window.addEventListener('touchstart', handleOnDown);
        window.addEventListener('touchend', handleOnDown);
        window.addEventListener('touchmove', handleOnDown);
    }
}

function handleOnDown(e) {
    if (e.touches)
        e = e.touches[0];

    track.dataset.mouseDownAt = e.clientX
};

function handleOnUp(e) {
    track.dataset.mouseDownAt = "0";
    track.dataset.prevPercentage = track.dataset.percentage;
}

function handleOnMove(e) {
    if (e.touches)
        e = e.touches[0];

    if (track.dataset.mouseDownAt === "0") return;

    const mouseDelta = parseFloat(track.dataset.mouseDownAt) - e.clientX,
        maxDelta = window.innerWidth / 2;

    const percentage = (mouseDelta / maxDelta) * -100,
        nextPercentageUnconstrained = parseFloat(track.dataset.prevPercentage) + percentage,
        nextPercentage = Math.max(Math.min(nextPercentageUnconstrained, 0), -100);

    track.dataset.percentage = nextPercentage;
    
    track.animate({
        transform: `translateX(${nextPercentage / 2}%)`
    }, { duration: 1200, fill: "forwards" });
}

export function stopDragTrack() {
    if (track) {

        track.animate({
            transform: `translateX(0%)`
        }, { duration: 200, fill: "forwards" });

        track.dataset.percentage = 0;

        window.removeEventListener('mousedown', handleOnDown);
        window.removeEventListener('mouseup', handleOnUp);
        window.removeEventListener('mousemove', handleOnMove);

        window.removeEventListener('touchstart', handleOnDown);
        window.removeEventListener('touchend', handleOnDown);
        window.removeEventListener('touchmove', handleOnDown);
    }
}